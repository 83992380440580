import React from 'react';
import { Calendar, Clock } from 'lucide-react';

const ScenarioItem = ({ scenario }) => {
    const getStatusColor = (status) => {
        const colors = {
            'In Progress': 'bg-yellow-100 text-yellow-800',
            'Ready': 'bg-green-100 text-green-800',
            'Failed': 'bg-red-100 text-red-800',
            'Pending': 'bg-gray-100 text-gray-800'
        };
        return colors[status] || 'bg-gray-100 text-gray-800';
    };

    const formatDate = (date) => {
        if (!date) return 'No date';
        return new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

    return (
        <div key={scenario.id} className="bg-white p-4 rounded-lg border border-gray-200 hover:border-blue-300 transition-colors">
            <div className="flex items-start justify-between">
                <div className="space-y-1 flex-1">
                    <div className="flex items-center gap-2">
                        <h4 className="font-medium text-lg">
                            {scenario.name || 'Unnamed Scenario'}
                        </h4>
                        <span className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(scenario.status)}`}>
                            {scenario.status}
                        </span>
                    </div>
                    <p className="text-gray-600 line-clamp-2">{scenario.description}</p>
                    <div className="flex items-center gap-4 text-sm text-gray-500">
                        <div className="flex items-center gap-1">
                            <Calendar className="w-4 h-4" />
                            <span>{formatDate(scenario.currentDateTime)}</span>
                        </div>
                        <div className="flex items-center gap-1">
                            <Clock className="w-4 h-4" />
                            <span>{scenario.currentDateTime ? new Date(scenario.currentDateTime).toLocaleTimeString() : 'No time'}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScenarioItem;