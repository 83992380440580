import React, { useState } from 'react';
import { Plus } from 'lucide-react';

const CreateScenarioForm = () => {
    const [scenarioText, setScenarioText] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Creating scenario:', scenarioText);
        setScenarioText('');
    };

    return (
        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
            <h3 className="text-lg font-semibold mb-4 flex items-center gap-2">
                <Plus className="w-5 h-5 text-blue-500" />
                Create A New Scenario
            </h3>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <textarea
                        value={scenarioText}
                        onChange={(e) => setScenarioText(e.target.value)}
                        placeholder="Enter your scenario description..."
                        className="w-full h-32 p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div className="flex justify-end">
                    <button
                        type="submit"
                        disabled={!scenarioText.trim()}
                        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
                    >
                        <Plus className="w-4 h-4" />
                        Create!
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CreateScenarioForm;